<template>
  <div>
    <!-- Page Title -->
    <h2 class="page-title-bar"><i class="ico ico-gateway"></i>{{ $t('Gateway Info') }}</h2>
    <!-- Search Area -->
    <div class="searchArea">
      <!-- IP Address Input -->
      <v-text-field v-model="search.ip" @keyup.enter="updateGatewayList(1)" :label="$t('IP Address')"
        :placeholder="$t('Input IP address')" class="form-input" clearable outlined dense hide-details></v-text-field>
      <!-- MAC ID Input -->
      <v-text-field v-model="search.mac" @keyup.enter="updateGatewayList(1)" :label="$t('MAC ID')"
        :placeholder="$t('Input MAC ID')" class="form-input ml-3" outlined dense hide-details clearable></v-text-field>
      <!-- Network Select -->
      <v-select v-model="search.network" :label="$t('Network')" :placeholder="$t('Select network status')"
        :items="searchOptions.network" item-text="text" item-value="abbr" class="form-select ml-3" outlined dense
        hide-details></v-select>
      <!-- Search Button -->
      <v-btn @click="updateGatewayList(1)" class="btn type-search ml-3" text>
        {{ $t('Search') }}
      </v-btn>
    </div>
    <div class="col-12 pl-0 pb-0 pt-0 pr-0 d-flex" style="margin-top:10px">
      <!-- Freeze Table -->
      <div class="col-5 pr-0">
        <v-data-table v-model="selected" @click:row="detailPopup" :headers="gatewayHeaders1" :hide-default-footer="true"
          :items="gateways" :item-class="getRowClass" :singleSelect="false" :options.sync="options"
          :server-items-length="totalGateways" class="tbl-type01 mt-10" show-select item-key="name">
          <!-- No Data Slot -->
          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
          <!-- Network Status Slot -->
          <template v-slot:[`item.networkStatus`]="{ item }">
            <p class="text-center">
              <i class="state-icon" :class="networkStatusStyle(item.networkStatus)"></i>
            </p>
          </template>
        </v-data-table>
      </div>
      <!-- Main Table -->
      <div class="col-7 pl-0">
        <v-data-table v-model="selected" @click:row="detailPopup" :headers="gatewayHeaders" :hide-default-footer="true"
          :items="gateways" :item-class="getRowClass" :singleSelect="false" :options.sync="options"
          :server-items-length="totalGateways" class="tbl-type01 mt-10 tblBorder" item-key="name">
          <!-- No Data Slot -->
          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
          <!-- Network Status Slot -->
          <template v-slot:[`item.networkStatus`]="{ item }">
            <p class="text-center">
              <i class="state-icon" :class="networkStatusStyle(item.networkStatus)"></i>
            </p>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Detail Modal -->
    <detailModal v-model="gatewayDetailDialog" :gateway="gateway" @fireUpdateGatewayList="updateGatewayList(1)" />
    <!-- Table Options -->
    <div class="table-options">
      <div>
        <!-- Add Button (for 'cloud' server type) -->
        <v-btn v-if="serverType === 'cloud'" @click.stop="addGatewayDialog = true" :disabled="notSelectedAdd" class="btn"
          text>{{ $t('Add') }}
        </v-btn>
        <!-- Add Cloud Modal -->
        <addCloudModal v-model="addGatewayDialog" />
        <!-- Add Button (for 'server' server type) -->
        <v-btn v-if="serverType === 'server'" @click.stop="addGatewayDialogServer = true"
          :disabled="addBtnDisabled || !disableaddbtn" class="btn ml-2" text>{{ $t('Add') }}
        </v-btn>
        <!-- Add Server Modal -->
        <addServerModal v-model="addGatewayDialogServer" @fireResetCondition="resetGatewayList"
          @openMsgBox="openMsgBox" />
        <!-- Delete Button -->
        <v-btn @click="deleteSelectedGateway" :disabled="notSelectedDelete" class="btn ml-2" text>{{ $t('Delete') }}
        </v-btn>
        <!-- Reboot Button -->
        <v-btn @click="rebootSelectedGateway" :disabled="notSelectedReboot" class="btn ml-2" text>{{ $t('Reboot') }}
        </v-btn>
        <!-- Export Button -->
        <v-btn @click="exportGatewayList" :disabled="exportDisabled" text class="btn ml-2">{{ $t('Export') }}</v-btn>
      </div>
      <!-- <div class="pageInfo"> {{ pageInfoText }} </div> -->
      <!-- <div>

        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages"
          :total-visible="7"
           class="pageAlign"
        ></v-pagination>
      </div> -->
    </div>
    <!-- Pagination Component -->
    <Pagination @paging="paging($event)" :pageInfoText="pageInfoText" :pageIndex="pageIndex" :rowPerPage="rowPerPage"
      :goToPageInput="goToPageInput" :totalPages="totalPages" @updatePage="updatePage($event)"></Pagination>
    <!-- Hidden Link Element for Exporting -->
    <a ref="link" :style="{ display: 'none' }" />
    <!-- Message Box Component -->
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </div>
</template>

<script>
// libraries
import { mapGetters } from 'vuex'
// mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
// utils
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'
// components
import detailModal from './modal/GatewayDetailModal'
import addCloudModal from './modal/AddGatewayModalCloud'
import addServerModal from './modal/AddGatewayModalServer'
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
// Importing CSS
import '@/assets/css/freeze.css'

export default {
  name: 'GatewayInfo',
  mixins: [MsgBoxParent, Pagenation],
  components: {
    detailModal,
    addCloudModal,
    addServerModal,
    Pagination
  },
  data () {
    return {
      user: null,
      gatewayDetailDialog: false,
      addGatewayDialog: false,
      addGatewayDialogServer: false,
      btnDisabledDetailed: false,
      btnDisabledAddDelete: false,
      btnDisabledReboot: false,
      btnDisabledExport: false,
      message: '',
      page: 1,
      pageIndex: 1,
      rowPerPage: '10',
      pageCount: 0,
      totalPages: 0,
      totalVisiblePages: 5,
      totalGateways: null,
      options: {},
      pageInfoText: '',
      requestConfig: {},
      selected: [],
      search: {
        network: null,
        ip: null,
        mac: null
      },
      gateways: [],
      gateway: {},
      // serverType: process.env.VUE_APP_PRODUCTION_TYPE
      ROW_CLASS: 'row-class',
      serverType: 'server'
    }
  },
  computed: {
    gatewayHeaders1 () {
      return [

        { text: this.$t('GATEWAY IP'), value: 'ipAddress' },
        { text: this.$t('GATEWAY NAME'), value: 'name' }

      ]
    },
    gatewayHeaders () {
      return [
        // { text: this.$t('GATEWAY IP'), value: 'ipAddress', width: '10%' },
        // { text: this.$t('GATEWAY NAME'), value: 'name', width: '14.3%' },
        { text: this.$t('MAC ID'), value: 'macAddress', width: '20%' },
        { text: this.$t('NTP SERVER IP'), value: 'ntpServerIp', width: '15%', sortable: false },
        { text: this.$t('DISPLAY NO.'), value: 'displayNo', width: '15%', sortable: false },
        { text: this.$t('LABELS CONNECTED'), value: 'labelCount', width: '15%', sortable: false },
        { text: this.$t('CONNECTIVITY'), align: 'center', value: 'networkStatus', width: '10%' },
        { text: this.$t('FIRMWARE'), align: 'center', value: 'gwVersion', width: '10%' },
        { text: this.$t('LAST CONNECTION TIME'), align: 'center', value: 'lastConnectionDate' }

      ]
    },
    searchOptions () {
      return {
        network: [
          { text: this.$t('ALL'), abbr: 'all' },
          { text: this.$t('Online'), abbr: 'online' },
          { text: this.$t('Not Ready'), abbr: 'notready' },
          { text: this.$t('Offline'), abbr: 'offline' }
        ]
      }
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    }),
    exportDisabled () {
      let btnDisabled = this.btnDisabledExport
      if (!btnDisabled) {
        btnDisabled = (this.gateways === null || this.gateways === undefined || this.gateways.length < 1)
      }
      return btnDisabled
    },
    addBtnDisabled () {
      return this.btnDisabledAddDelete
    },
    notSelectedReboot () {
      let btnDisabled = this.btnDisabledReboot
      if (!btnDisabled) {
        btnDisabled = (this.selected.length < 1)
      }
      return btnDisabled
    },
    notSelectedDelete () {
      let btnDisabled = this.btnDisabledAddDelete
      if (!btnDisabled) {
        btnDisabled = (this.selected.length < 1)
      }
      return btnDisabled
    },
    notSelected () {
      return (this.selected.length < 1)
    },
    disableaddbtn () {
      return (this.selectedStore !== '')
    }
  },
  watch: {
    options: {
      handler () {
        this.updateGatewayList(1)
        this.page = 1
      },
      deep: true
    },
    store: {
      handler () {
        this.updateGatewayList(1)
        this.page = 1
      }
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    },
    search: {
      handler (newSearchBy) {
        const gatewayInfo = {
          search: newSearchBy
        }
        setTempObj('gatewayInfo', gatewayInfo)
      },
      deep: true
    }
  },
  created () {
    // Get the user from the auth store state
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.clearTabindex()
    // button 권한 체크
    // Gateway Info : Get Detailed
    this.$store.dispatch('auth/getDisabledBtn', '4100').then((flag) => {
      this.btnDisabledDetailed = flag
    })
    // Gateway Info : Add / Delete
    this.$store.dispatch('auth/getDisabledBtn', '4101').then((flag) => {
      this.btnDisabledAddDelete = flag
    })
    // Gateway Info : Reboot
    this.$store.dispatch('auth/getDisabledBtn', '4102').then((flag) => {
      this.btnDisabledReboot = flag
    })
    // Gateway Info : Export
    this.$store.dispatch('auth/getDisabledBtn', '4103').then((flag) => {
      this.btnDisabledExport = flag
    })
    // Retrieve gatewayInfo from session data
    const gatewayInfoSessionData = getTempObj('gatewayInfo')
    if (
      !commons.isNull(gatewayInfoSessionData) &&
      !commons.isNull(gatewayInfoSessionData.search)
      // &&
      // (
      //   !commons.isNull(gatewayInfoSessionData.search.mac) ||
      //   !commons.isNull(gatewayInfoSessionData.search.ip)
      // )
    ) {
      this.search = gatewayInfoSessionData.search
    }
    // Check if network parameter is provided in the route
    if (!commons.isNull(this.$route.params.network)) {
      this.resetSearchCondition()
      this.search.network = this.$route.params.network
    }
    // Enable selected stores
    EventBus.$emit('enableSelectedStores', true)
    // Store 선택하지 않은경우 사용자에게 Store 선택 요구
    // Check if store is selected, if not prompt the user to select a store
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.updateGatewayList(1)
    }
  },
  methods: {
    // Clear tabindex for v-icon elements
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // Reset the search condition to default values
    resetSearchCondition () {
      this.page = 1
      this.search = { network: 'all', ip: null, mac: null }
    },
    // Reset the gateway list by resetting the search condition and updating the gateway list
    resetGatewayList () {
      this.resetSearchCondition()
      this.updateGatewayList(1)
    },
    // Perform pagination by updating the gateway list for the specified page
    paging (input) {
      this.goToPageInput = input
      this.updateGatewayList(input)
    },
    // Update the number of items per page and update the gateway list accordingly
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    // Save the request configuration for exporting the gateway list
    saveRequestConfig: function (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params
      }
      this.requestConfig = requestConfig
    },
    // Export the gateway list by calling the exportFiles utility function
    exportGatewayList () {
      exportFiles(this.requestConfig, this.$refs.link, 'GatewayList.xlsx')
    },
    // Get the CSS class for the network status badge based on the network status value
    networkStatusStyle (networkStatus) {
      const color = codes.networkStatusColor.get(networkStatus)
      if (commons.isNull(color)) return 'bg-danger'
      return color
    },
    // Open a dialog for displaying detailed information about a gateway
    detailPopup (value) {
      if (this.btnDisabledDetailed === true) return
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          gateway: commons.toDashLessMacAddr(value.macAddress)
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getGatewayDetail.method,
          codes.requests.getGatewayDetail.url,
          config
        )
        .then(res => {
          if (res.data !== '') {
            this.gateway = res.data
            this.gatewayDetailDialog = true
          }
        })
    },
    // Handle the response for retrieving the gateway list
    handleGetGwResponse (res) {
      if (res.status === 204) {
        this.gateways = []
        this.totalGateways = this.gateways.length
        const headers = {}
        headers['x-totalelements'] = 0
        this.pageInfoText = commons.getPageInfoText(headers)
        this.totalPages = 0
        this.selected = []
      } else {
        this.gateways = this.convertProductsDate(res.data.gatewayList)
        this.saveRequestConfig(res.config)
        if (this.gateways instanceof Array) this.totalGateways = this.gateways.length
        this.pageInfoText = commons.getPageInfoText(res.headers)
        if (commons.isValidStr(res.headers['x-totalpages'])) {
          this.totalPages = res.headers['x-totalpages'] * 1
        }
        this.pageIndex = res.headers['x-number'] * 1 + 1
        this.pageCount = res.headers['x-totalpages'] * 1
        this.selected = []
      }
    },
    // Handle the error for retrieving the gateway list
    handleGetGwError (error) {
      this.gateways = []
      this.totalGateways = this.gateways.length
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      this.pageIndex = 0
      this.selected = []
      console.debug(`Could not find any gateway. ${error}`)
    },
    // Build the search parameters for retrieving the gateway list
    buildSearchParams (params, search) {
      if (search.network !== null && search.network !== 'all') params.network = search.network
      if (!commons.isNull(search.ip)) params.ip = search.ip
      if (!commons.isNull(search.mac)) params.mac = search.mac
      return params
    },
    // Convert the dates in the gateway list to a human-readable format
    convertProductsDate (gatewayList) {
      return gatewayList.map(product => {
        product.lastConnectionDate = commons.convertDate(product.lastConnectionDate)
        return product
      })
    },
    // Get the sort key for the gateway list based on the selected sort option
    getGatewaySortKey (sortKey) {
      switch (sortKey) {
        case 'networkStatus': sortKey = 'state'
          break
        default: break
      }
      return sortKey
    },
    // Build the parameters for retrieving the gateway list
    buildGetGatewaysParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      let params = { store: this.store.code }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getGatewaySortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params = this.buildSearchParams(params, this.search)
      params.company = this.$store.state.auth.user.company
      params.store = this.store.code
      return params
    },
    // Update the gateway list by retrieving the gateway data from the server
    updateGatewayList (page) {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) EventBus.$emit('openSelectedStores')
      const params = this.buildGetGatewaysParams()
      if (commons.isNull(params.store)) return
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.getGateways.method,
          codes.requests.getGateways.url,
          config
        )
        .then(res => {
          this.handleGetGwResponse(res)
        })
        .catch(error => {
          this.handleGetGwError(error)
        })
    },
    // Get the selected MAC addresses
    getSelectedMacAddr (selected) {
      const gateways = []
      if (this.selected.length < 1) return
      this.selected.forEach(gateway => {
        const dashLessMacAddr = commons.toDashLessMacAddr(gateway.macAddress)
        gateways.push(dashLessMacAddr)
      })
      return gateways.join(',').toString()
    },
    // Get the selected IDs
    getSelectedIds (selected) {
      const gateways = []
      if (this.selected.length < 1) return
      this.selected.forEach(gateway => {
        const dashLessMacAddr = gateway.id
        gateways.push(dashLessMacAddr)
      })
      //       arr = gateways.map(Number);

      return gateways.map(Number)
    },
    // Handle the success response for an API call
    handleSuccess (res, msg) {
      this.page = 1
      this.selected = []
      EventBus.$emit('toggleLoadingModal', msg)
      this.openMsgBox(this.$t(res.data.responseMessage))
      this.updateGatewayList(1)
    },
    // Handle the error response for an API call
    handleError (error, msg) {
      EventBus.$emit('toggleLoadingModal', '')
      this.openMsgBox(this.$t(msg))
      this.updateGatewayList(1)
      console.debug(`Failed to perform the given operation on gateways. ${error}`)
    },
    // Delete the selected gateways by calling the deleteGateway API
    deleteSelectedGateway () {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
        EventBus.$emit('openSelectedStores')
        return
      }
      const gatewayId = this.getSelectedIds(this.selected)
      const config = {
        params: {
          company: this.user.company,
          store: this.selectedStore,
          ids: gatewayId
        }
      }
      const msg = this.$t('Deleting Gateways...')
      EventBus.$emit('toggleLoadingModal', msg)
      this.$utils
        .callAxios(
          codes.requests.deleteGateway.method,
          codes.requests.deleteGateway.url,
          config
        )
        .then(res => {
          this.handleSuccess(res, msg)
        })
        .catch(error => {
          if (error.response.data.responseMessage !== null || error.response.data.responseMessage !== '') {
            this.handleError(error, this.$t(error.response.data.responseMessage))
          }
          if (error.response.data.responseMessage === null || error.response.data.responseMessage === '') {
            this.handleError(error, this.$t('Failed to register gateways.'))
          }
        })
    },
    getRowClass () { return codes.ROW_CLASS },
    rebootSelectedGateway () {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
        EventBus.$emit('openSelectedStores')
        return
      }
      if (this.selected.find(sel => sel.networkStatus === 'DISCONNECTED') !== undefined) {
        this.openMsgBox(this.$t('Disconnected gateways can not be rebooted.'))
        return
      }
      const gateways = this.getSelectedMacAddr(this.selected)
      const config = {
        params: {
          company: this.user.company,
          store: this.selectedStore,
          gateways: gateways
        }
      }
      const msg = 'Rebooting Gateways...'
      EventBus.$emit('toggleLoadingModal', msg)
      this.$utils
        .callAxiosWithBody(
          codes.requests.rebootGateway.method,
          codes.requests.rebootGateway.url,
          {},
          config
        )
        .then(res => {
          this.handleSuccess(res, msg)
        })
        .catch(error => {
          this.handleError(error, this.$t('Failed to reboot gateways.'))
        })
    }
  }
}
</script>
<style scoped>
.hidden {
  display: none;
}

.pageAlign {
  float: right;

}
</style>

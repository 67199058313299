var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-gateway" }),
        _vm._v(_vm._s(_vm.$t("Gateway Info")))
      ]),
      _c(
        "div",
        { staticClass: "searchArea" },
        [
          _c("v-text-field", {
            staticClass: "form-input",
            attrs: {
              label: _vm.$t("IP Address"),
              placeholder: _vm.$t("Input IP address"),
              clearable: "",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateGatewayList(1)
              }
            },
            model: {
              value: _vm.search.ip,
              callback: function($$v) {
                _vm.$set(_vm.search, "ip", $$v)
              },
              expression: "search.ip"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-input ml-3",
            attrs: {
              label: _vm.$t("MAC ID"),
              placeholder: _vm.$t("Input MAC ID"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateGatewayList(1)
              }
            },
            model: {
              value: _vm.search.mac,
              callback: function($$v) {
                _vm.$set(_vm.search, "mac", $$v)
              },
              expression: "search.mac"
            }
          }),
          _c("v-select", {
            staticClass: "form-select ml-3",
            attrs: {
              label: _vm.$t("Network"),
              placeholder: _vm.$t("Select network status"),
              items: _vm.searchOptions.network,
              "item-text": "text",
              "item-value": "abbr",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.search.network,
              callback: function($$v) {
                _vm.$set(_vm.search, "network", $$v)
              },
              expression: "search.network"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "btn type-search ml-3",
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.updateGatewayList(1)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Search")) + " ")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-12 pl-0 pb-0 pt-0 pr-0 d-flex",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c(
            "div",
            { staticClass: "col-5 pr-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 mt-10",
                  attrs: {
                    headers: _vm.gatewayHeaders1,
                    "hide-default-footer": true,
                    items: _vm.gateways,
                    "item-class": _vm.getRowClass,
                    singleSelect: false,
                    options: _vm.options,
                    "server-items-length": _vm.totalGateways,
                    "show-select": "",
                    "item-key": "name"
                  },
                  on: {
                    "click:row": _vm.detailPopup,
                    "update:options": function($event) {
                      _vm.options = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.networkStatus",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", { staticClass: "text-center" }, [
                              _c("i", {
                                staticClass: "state-icon",
                                class: _vm.networkStatusStyle(
                                  item.networkStatus
                                )
                              })
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-7 pl-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 mt-10 tblBorder",
                  attrs: {
                    headers: _vm.gatewayHeaders,
                    "hide-default-footer": true,
                    items: _vm.gateways,
                    "item-class": _vm.getRowClass,
                    singleSelect: false,
                    options: _vm.options,
                    "server-items-length": _vm.totalGateways,
                    "item-key": "name"
                  },
                  on: {
                    "click:row": _vm.detailPopup,
                    "update:options": function($event) {
                      _vm.options = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.networkStatus",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", { staticClass: "text-center" }, [
                              _c("i", {
                                staticClass: "state-icon",
                                class: _vm.networkStatusStyle(
                                  item.networkStatus
                                )
                              })
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c("detailModal", {
        attrs: { gateway: _vm.gateway },
        on: {
          fireUpdateGatewayList: function($event) {
            return _vm.updateGatewayList(1)
          }
        },
        model: {
          value: _vm.gatewayDetailDialog,
          callback: function($$v) {
            _vm.gatewayDetailDialog = $$v
          },
          expression: "gatewayDetailDialog"
        }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _vm.serverType === "cloud"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { disabled: _vm.notSelectedAdd, text: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.addGatewayDialog = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Add")) + " ")]
                )
              : _vm._e(),
            _c("addCloudModal", {
              model: {
                value: _vm.addGatewayDialog,
                callback: function($$v) {
                  _vm.addGatewayDialog = $$v
                },
                expression: "addGatewayDialog"
              }
            }),
            _vm.serverType === "server"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn ml-2",
                    attrs: {
                      disabled: _vm.addBtnDisabled || !_vm.disableaddbtn,
                      text: ""
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.addGatewayDialogServer = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Add")) + " ")]
                )
              : _vm._e(),
            _c("addServerModal", {
              on: {
                fireResetCondition: _vm.resetGatewayList,
                openMsgBox: _vm.openMsgBox
              },
              model: {
                value: _vm.addGatewayDialogServer,
                callback: function($$v) {
                  _vm.addGatewayDialogServer = $$v
                },
                expression: "addGatewayDialogServer"
              }
            }),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { disabled: _vm.notSelectedDelete, text: "" },
                on: { click: _vm.deleteSelectedGateway }
              },
              [_vm._v(_vm._s(_vm.$t("Delete")) + " ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { disabled: _vm.notSelectedReboot, text: "" },
                on: { click: _vm.rebootSelectedGateway }
              },
              [_vm._v(_vm._s(_vm.$t("Reboot")) + " ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { disabled: _vm.exportDisabled, text: "" },
                on: { click: _vm.exportGatewayList }
              },
              [_vm._v(_vm._s(_vm.$t("Export")))]
            )
          ],
          1
        )
      ]),
      _c("Pagination", {
        attrs: {
          pageInfoText: _vm.pageInfoText,
          pageIndex: _vm.pageIndex,
          rowPerPage: _vm.rowPerPage,
          goToPageInput: _vm.goToPageInput,
          totalPages: _vm.totalPages
        },
        on: {
          paging: function($event) {
            return _vm.paging($event)
          },
          updatePage: function($event) {
            return _vm.updatePage($event)
          }
        }
      }),
      _c("a", { ref: "link", style: { display: "none" } }),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }